.confirmPassword-box-all {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	.ant-btn {
		margin-top: 20px;
		text-align: center;
		width: 100%;
		max-width: 176px;
		height: 57px;
		background-color: #009e83;
		border-radius: 3px !important;
		font-size: 14px;
		font-weight: 700;
		line-height: 19px;
		letter-spacing: 0em;
		text-align: center;
		color: #ffffff;
		&:hover {
			color: #009e83;
			background-color: #ffffff;
		}
	}

	.confirmPassword-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 612px;
		min-height: 268px;
		border-radius: 3px;
		background: #ffffff;
	}
	.confirmPassword {
		display: flex;
		flex-direction: column;
		align-items: center;
		align-self: center;
		padding: 20px 15px;
		justify-content: center;
		.title {
			text-align: center;
			font-size: 20px;
			font-weight: 400;
			line-height: 22px;
			letter-spacing: 0em;
			text-align: center;
			color: #009e83;
		}
		.text {
			margin: 0px !important;
			padding: 0px !important;
			max-width: 450px;
			font-size: 16px;
			font-weight: 400;
			line-height: 22px;
			letter-spacing: 0em;
			text-align: center;
			color: black;
		}
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;