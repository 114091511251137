@import 'utils/less/_variables.less';

.layout {
	background: @ui-back !important;
	min-height: 100vh;
}

// IOS BUG WITH SCROLL, WHEN DRAAWER OPENED
// html,
// body {
// 	height: 100%;
// 	margin: 0;
// 	padding: 0;
// }
body:has(div > .ant-drawer.ant-drawer-open) {
	overflow: hidden;
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
}

@media screen and (max-width: 780px) {
	.ant-picker-panels {
		flex-direction: column;
	}
	.ant-picker-panel:nth-child(2) {
		.ant-picker-header {
			order: 2;
		}
	}
}

// // // // // // // // //
.ant-picker-today-btn {
	color: #009e83 !important;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-transform: uppercase;
}
.content {
	width: 100%;
	max-width: 1300px;
	padding: 0px 30px;
	margin: 0px auto;
	display: flex;
	// BUG on contacts page (its adding y-scroll)
	// overflow-y: hidden;
}

// Makes layout more modern for account page (uncomment css below)
// @media screen and (max-width: 870px) {
// 	.account-pages {
// 		.link-page-account,
// 		.acount-title {
// 			padding: 0 30px;
// 		}
// 	}
// 	.content:has(.account-pages) {
// 		max-width: none;
// 		padding: 0;
// 	}
// }

.content:has(.schedule) {
	max-width: none;
	padding: 0;
}
.ant-layout:has(.not-found) {
	background-color: #f2f3f6;
}
.transparentLoading {
	height: 100vh;
	width: 100vw;
	background: #f2f3f6;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9000000;
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		max-width: 500px;
	}
}
@media screen and (max-width: 480px) {
	.content {
		padding: 0px 15px;
	}
	.transparentLoading {
		img {
			max-width: 200px;
		}
	}
}
.content :first-child {
	flex: 1;
}

// LOGIN INPUT AND CONTACTS INPUT
.ant-form-item-required,
.ant-form-item-label {
	color: #121212;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	&::before {
		// display: none !important;
	}
}

// POPUP STYLES

.confirm-modal {
	& svg {
		color: #009e83 !important;
	}
	.ant-modal-confirm-title {
		top: 5px;
		position: relative;
	}
	.ant-modal-confirm-content {
		padding-left: 0;
	}
	.ant-modal-confirm-btns {
		justify-content: center;
		& svg {
			color: #009e83 !important;
		}
		.ant-btn-default {
			color: #121212;
			// &:hover {
			// 	background-color: red;
			// 	color: red !important;
			// }
		}
		.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
			border: 1px solid red;
			color: red;
		}
		&:hover {
			color: #121212;
		}

		.ant-btn-primary {
			background-color: #009e83;
			&:hover {
				border: 1px solid #009e83;
				border-radius: 8px;
				color: #009e83;
				background-color: #fff;
			}
		}
	}
}

.leaflet-container {
	height: 730px;
}
.schedule__map-container {
	height: 300px; /* Adjust the height as needed */
}

.ant-input-disabled,
.ant-picker-disabled,
.ant-picker-input > input[disabled],
.ant-select-disabled .ant-select-selector {
	color: rgba(0, 0, 0, 0.5) !important;
	background-color: unset !important;
}
.SIGN_IN {
	.ant-input-affix-wrapper {
		padding: 3px 11px;
	}
}
.ant-menu-light .ant-menu-item:not(.ant-menu-item-disabled):focus-visible,
.ant-menu-light .ant-menu-submenu-title:not(.ant-menu-item-disabled):focus-visible {
	outline: none;
}

.ant-carousel {
	max-width: 1240px;
}
// css styles file
.ant-carousel,
.slick-slider,
.slick-list,
.slick-track {
	height: 100% !important;
}

.slick-slide > div:first-child {
	height: 100% !important;
}
.ant-select-disabled.ant-select .ant-select-selector {
	background-color: white !important;
	border: 1px solid transparent;
}
.schedule {
	.anticon.anticon-close-circle {
		position: relative;
		right: 10px;
		top: -7px;
		svg {
			height: 25px;
			width: 25px;
		}
	}
}

@primary-color: #009E83;@ui-blue: #38456F;@ui-black: #111010;@ui-back: #F9F9F9;@ui-red: #CE1126;@ui-grey: rgba(108, 93, 211, 0.05);@ui-border: #e5e5e5;@ui-blue-hover: #FAFBFF;